import React from "react";
import Title from "../../../components/Title";
import continuous from "../../../assets/continuous.png";

import styles from "./styles.module.css";

const ContinuousIntegration = () => (
  <div className={styles.container} id="continuousIntegration">
    <div className={styles.containerTitle}>
      <Title
        labelNumber={4}
        label="We make your process efficient and"
        icon={continuous}
      />
    </div>
    <div className={styles.bigContainer}>
      <div className={styles.wrapper}>
        <iframe
          title="cloud"
          src="https://player.vimeo.com/video/413592101"
          width="640"
          height="338"
          frameborder="0"
          allow="fullscreen"
          allowfullscreen
        />
      </div>
      <div className={styles.infoContainer}>
        <p className={styles.paragraphStyle}>
          <strong>Innomius® </strong>has witnessed the importance for companies
          to develop internal procedures where operation does not depend on
          skilled individuals.
        </p>
        <p className={styles.paragraphStyle}>
          This means their development cycles should be automated via a fully
          integrated Continuous Integration process where developed applications
          are built, tested and deployed automatically. It is important to limit
          the human intervention required to convert the software from source to
          bytes.
        </p>
        <p className={styles.paragraphStyle}>
          Similar to a chocolate factory conveyor line the process should be
          assembled from beginning to end.
        </p>
        <ul className={styles.list}>
          <li className={styles.list}>
            <b>• Pipelines (build, test and deploy)</b>
          </li>
          <li>
            <b>• Version release management</b>
          </li>
          <li>
            <b>• Kubernetes + Docker</b>
          </li>
        </ul>
      </div>
    </div>
  </div>
);

export default ContinuousIntegration;
