import React from "react";
import ravent from "../../../assets/ravent.png";
import newproduct from "../../../assets/newproduct.png";

import styles from "./styles.module.css";

const Ravent = () => (
  <div
    className={styles.container}
    id="ravent"
    style={{ backgroundImage: `url(${ravent})` }}
  >
    <div className={styles.wrapper}>
      <span>Explore Ravent our</span>
      <div className={styles.imageContainer}>
        <img src={newproduct} alt="" className={styles.icon} />
      </div>

      <a href="https://ravent.com/" className={styles.link}>
        View product
      </a>
    </div>
  </div>
);

export default Ravent;
