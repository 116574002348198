import React from "react";
import Title from "../../../components/Title";
import styles from "./styles.module.css";
import cloud from "../../../assets/cloud.png";
import cloudImage from "../../../assets/cloud.jpg";

const Cloud = () => (
  <div className={styles.container} id="cloud">
    <img className={styles.cloudImage} src={cloudImage} alt="" />
    <div className={styles.textContainer}>
      <Title
        labelNumber="3"
        label="We configure your "
        colorNumber="white"
        icon={cloud}
      />
      <div className={styles.infoContainer}>
        <ul className={styles.list}>
          <li className={styles.listItem}>• Architecture and Design</li>
          <li className={styles.listItem}>• Setup and Configuration</li>
          <li className={styles.listItem}>• Operating</li>
          <li className={styles.listItem}>• Monitoring and Alerting</li>
          <li className={styles.listItem}>• Security</li>
        </ul>
      </div>
    </div>
  </div>
);

export default Cloud;
