import React from "react";
import quality from "../../../assets/quality.png";

import styles from "./styles.module.css";

const QualityAssurance = () => (
  <div className={styles.container} id="cloud">
    <div className={styles.wrapper}>
      <div className={styles.gradient} />
      <iframe
        title="cloud"
        src="https://player.vimeo.com/video/413484557?autoplay=1&loop=1&autopause=0&controls=0"
        width="640"
        height="338"
        frameborder="0"
        allow="autoplay; fullscreen"
        allowfullscreen
      />
    </div>
    <div className={styles.titleContainer}>
      <div className={styles.containerTitle}>
        <h2 className={styles.title}>
          5 <p className={styles.point}>. </p>
          <div className={styles.imageContainer}>
            <img className={styles.image} src={quality} alt="" />
          </div>
          Assurance
        </h2>
      </div>
      <div className={styles.infoContainer}>
        <h3 className={styles.infoTitle}>Make sure deployments are OK</h3>
        <p className={styles.paragraphStyle}>
          By implementing a automated functional testing system, companies can
          reduce risks every time they deploy new functionality to their
          production environments. But automating tests is not the only way of
          ensuring quality, It is important to implement code reviews every time
          a pull request is created.
        </p>
        <p className={styles.paragraphStyle}>
          These sessions also spark tech debates and internal engineering
          conversations, improving your workplace culture
        </p>
        <p className={styles.paragraphStyle}>
          Code reviews and developing code at the same time can be time
          consuming for one team. Innomius shares the burden by reviewing the
          code so that your team can stay focused.
        </p>
        <div className={styles.listWrapper}>
          <ul className={styles.listText}>
            <li>• Automated error checking via Linting</li>
            <li>• Automated code style and format fixing</li>
            <li>• Unitary tests</li>
            <li>• Code review as a staple of internal culture</li>
          </ul>
          <ul className={styles.listText}>
            <li>
              • Pull request guidelines that are quick and self explanatory
            </li>
            <li>• Implement CI pipelines</li>
            <li>• Reuse of code between web apps and mobile apps</li>
            <li>• Use git sub-modules to expedite development in codebase</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
);

export default QualityAssurance;
