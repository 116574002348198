import React from "react";
import styles from "./styles.module.css";

const About = () => (
  <div className={styles.container} id="about">
    <p className={styles.textInfo}>
      <strong>Innomius®</strong> Technologies is a software development company
      based in Mexico City. Founded in 2001, our team provides custom end-to-end
      IT services in web, mobile and cloud as well as UX/UI design, software
      development and continuous integration. 
    </p>
  </div>
);

export default About;
