import React from "react";
import Title from "../../../components/Title";
import team from "../../../assets/team.png";
import reach from "../../../assets/reach.svg";
import strength from "../../../assets/strength.svg";
import shift from "../../../assets/shift.svg";
import styles from "./styles.module.css";

const data = [
  {
    image: shift,
    name: "Extend your shift",
    text: [
      "Hiring a team in a different time zone through us means your company profits from running a 14 hour shift by getting more done in a single day, than ever before. A team made up of developers based in Europe and South American will have a work day that overlaps for a few hours. This extended shift, means development is running nearly ”around the clock.”",
    ],
  },
  {
    image: reach,
    name: "Extend your reach",
    text: [
      "We help American companies to support tech operations throughout Europe.",
      "We help European companies to support tech operations in the Americas.",
    ],
  },
  {
    image: strength,
    name: "Extend your strength",
    text: [
      "It's proven that teams made up of employees with mixed skills or team who have previous experience working together are better able to communicate efficiently and effectively.",
    ],
  },
];

const InfoSection = ({ data }) => {
  const { name, text, image } = data;
  return (
    <div className={styles.infoContainer}>
      <img className={styles.logo} src={image} alt="" />
      <h3 className={styles.subtitle}>{name}</h3>
      {text.map((item) => (
        <p className={styles.paragraph}>{item}</p>
      ))}
    </div>
  );
};

const Empower = () => (
  <div className={styles.container} id="services">
    <div className={styles.wrapper}>
      <iframe
        title="Empower"
        src="https://player.vimeo.com/video/413119148?autoplay=1&loop=1&autopause=0&controls=0"
        width="660"
        height="358"
        frameborder="0"
        allow="autoplay; fullscreen"
        allowfullscreen
      />
      <div className={styles.titleContainer}>
        <Title
          labelNumber="1"
          label="We empower your"
          colorNumber="white"
          icon={team}
        />
      </div>
    </div>

    <div className={styles.containerSize}>
      {data.map((item) => (
        <InfoSection data={item} />
      ))}
    </div>
  </div>
);

export default Empower;
