import React from "react";
import logoImage from "../../assets/logo.png";
import styles from "./styles.module.css";

const links = [
  { to: "about", text: "Why us " },
  { to: "services", text: "Services " },
  { to: "contact", text: "Contact" },
];

function scrollTo(id) {
  const target = document.getElementById(id);
  target.scrollIntoView({ behavior: "smooth" });
}

const Navbar = () => (
  <div className={styles.navbar}>
    <div className={styles.wrapper}>
      <div className={styles.logoContainer}>
        <img src={logoImage} alt="innomius" />
      </div>
      <div className={styles.links}>
        {links.map((item) => (
          <button className={styles.button} onClick={() => scrollTo(item.to)}>
            {item.text}
          </button>
        ))}
      </div>
    </div>
  </div>
);

export default Navbar;
