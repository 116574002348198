import React from "react";
import styles from "./styles.module.css";
import phrase from "../../../assets/phrase.svg";
import Navbar from "../../../components/Navbar";

export default function Landing() {
  return (
    <div className={styles.container}>
      <div className={styles.containerLanding}>
        <Navbar />
        <div className={styles.phraseContainer}>
          <img className={styles.phrase} src={phrase} alt="" />
        </div>
      </div>
    </div>
  );
}
