import React from "react";
import style from "./styles.module.css";

const Title = (props) => (
  <div className={style.container}>
    <h2 className={style.title} style={{ color: props.colorNumber }}>
      {props.labelNumber} <div className={style.point}>.</div> {props.label}
      <div className={style.imageContainer}>
        CODE
        <img
          className={style.image}
          style={{ width: props.imageSize }}
          src={props.icon}
          alt=""
        />
      </div>
    </h2>
  </div>
);

export default Title;
