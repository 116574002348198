import React from "react";
import logo from "../../../assets/logo.png";
import usLogo from "../../../assets/us.png";

import styles from "./styles.module.css";

const Contact = () => (
  <div className={styles.container} id="contact">
    <div className={styles.infoContainer}>
      <div className={styles.info}>
        <img className={styles.imageLogo} src={logo} alt="" />
        <p className={styles.title}>Contact</p>
        <img className={styles.image} src={usLogo} alt="" />
      </div>
      <div className={styles.addressContainer}>
        <p className={styles.officeTitle}>Mexico City Offices:</p>
        <p className={styles.text}>
          Av. Homero 1425-105,
          <br />
          Polanco, CDMX, 11560, México
          <br />
          +52 (55) 4440.0624
        </p>
      </div>
    </div>
    <div className={styles.formContainer}>
      <div className={styles.inputWrapper}>
        <label className={styles.formText} htmlFor="name">
          Your name*
        </label>
        <input id="name" className={styles.inputContainer} />
      </div>
      <div className={styles.inputWrapper}>
        <label className={styles.formText} htmlFor="email">
          Your email*
        </label>
        <input id="email" className={styles.inputContainer} />
      </div>
      <div className={styles.inputWrapper}>
        <label className={styles.formText} htmlFor="company">
          Your company name*
        </label>
        <input id="company" className={styles.inputContainer} />
      </div>
      <div className={styles.buttonContainer}>
        <button className={styles.buttonText}>Enviar</button>
      </div>
    </div>
  </div>
);

export default Contact;
