import React from "react";
import Title from "../../../components/Title";
import code from "../../../assets/code.png";
import codeImage from "../../../assets/code.jpg";

import styles from "./styles.module.css";

const CodeDevelop = () => (
  <div className={styles.container} id="cloud">
    <div className={styles.textWrapper}>
      <div className={styles.containerTitle}>
        <Title
          labelNumber="2"
          label="We help you develop"
          colorNumber="#000"
          icon={code}
        />
      </div>
      <div className={styles.infoContainer}>
        <h3 className={styles.subtitle}>Mobile applications</h3>
        <ul className={styles.list}>
          <li> • Reactive Apps with great performance</li>
          <li> • We build Modular and re-usable components</li>
        </ul>

        <h3 className={styles.subtitle}>API Micro-services</h3>
        <p>
          Operating a site can be complicated. Micro-services help to define the
          scope and to minimize operational risk by giving you the flexibility
          to deploy self containing services.
        </p>
        <br></br>
        <ul className={styles.list}>
          <li>• Keep business logic well organized and clean</li>
          <li>• Micro services are your intellectual property</li>
          <li>• They defeat monolithic designs</li>
          <li>• Interact with other services in a clean and structured way</li>
          <li>• May use different tech stacks as needed</li>
          <li>• Ability to deploy and roll back quickly</li>
        </ul>
      </div>
    </div>
    <div className={styles.imageContainer}>
      <img className={styles.codeImage} src={codeImage} alt="" />
    </div>
  </div>
);

export default CodeDevelop;
