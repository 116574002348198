import React, { Component } from "react";
import Landing from "./Landing";
import About from "./About";
import Empower from "./Empower";
import CodeDevelop from "./CodeDevelop";
import Cloud from "./Cloud";
import ContinuousIntegration from "./ContinuousIntegration";
import QualityAssurance from "./QualityAssurance";
import Contact from "./Contact";
import Ravent from "./Ravent";
import Footer from "../../components/Footer";

class Home extends Component {
  render() {
    return (
      <>
        <Landing />
        <About />
        <Empower />
        <CodeDevelop />
        <Cloud />
        <ContinuousIntegration />
        <QualityAssurance />
        <Ravent />
        <Contact />
        <Footer />
      </>
    );
  }
}

export default Home;
